<template>
  <b-form-textarea
      :id="block.id+'_'+question.id"
      v-model="data[block.id][question.id]"
      :placeholder="$t('report.textarea_placeholder')"
      rows="3"
      max-rows="6"
  ></b-form-textarea>
</template>

<script>
import QuestionCommon from "./QuestionCommon.vue";
export default {
  mixins: [QuestionCommon],
}
</script>