<template>
  <div id="app">
    <SelectLanguage v-if="isVisible"></SelectLanguage>
    <router-view/>
  </div>
</template>

<script>
import SelectLanguage from '@/components/SelectLanguage.vue';
export default {
  components: {
    SelectLanguage,
  },
  data() {
    return {
      isVisible: true
    }
  },
  mounted() {
    window.addEventListener('current-view-localstorage-changed', (event) => {
      console.log(event.detail.storage);
      if(event.detail.storage === "Report") {
          this.isVisible = false;
        } else {
          this.isVisible = true;
        }
    })
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

@font-face {
  font-family: "ddin";
  src: local("D-DIN"),
  url('./assets/fonts/D-DIN2014/DIN2014-Regular.ttf') format("truetype");
}

body {
  background: linear-gradient(122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
  font-size: 0.8em;
}

#app {
  .tab-content{
    margin-top:2em;
  }
  font-family: "ddin", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
  padding: 0 3em;
  @media (max-width: 576px) {
      padding:0;
  }

  #bg-app{
    position: absolute;
    top: 160px;
    right:0;
    z-index: -1;
  }
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
