<template>
  <b-row>
    <b-col sm="2">
      <label>{{ question.min }} <span v-if="question.max==='100'">%</span></label>
      <span v-if="question.label_min!==''"><br>{{ question.label_min }}</span>
    </b-col>
    <b-col sm="8" class="range_bubble">
      <output class="bubble"
              :class="'range_'+block.id+'_'+question.id">{{ data[block.id][question.id] }}</output>
      <b-form-input :id="block.id+'_'+question.id" size="sm" placeholder="" type="range"
                    v-model="data[block.id][question.id]" :min="question.min" :max="question.max"
                    @input="setBubble(block.id,question.id,question.min,question.max)"></b-form-input>
    </b-col>
    <b-col sm="2">
      <label>{{ question.max }} <span v-if="question.max==='100'">%</span></label>
      <span v-if="question.label_max!==''"><br>{{ question.label_max }}</span>
    </b-col>
  </b-row>
</template>

<script>
import QuestionCommon from "./QuestionCommon.vue";
export default {
  mixins: [QuestionCommon],
  methods: {
    setBubble(b_id, q_id, min, max) {
      const val = this.data[b_id][q_id];
      const newVal = Number(((val - min) * 100) / (max - min));
      document.querySelector('.range_' + b_id + '_' + q_id).innerHTML = val;
      document.querySelector('.range_' + b_id + '_' + q_id).style.left = `calc(${newVal}%)`;
    }
  }
}
</script>