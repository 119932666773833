<template>
  <b-form-input :id="block.id+'_'+question.id" size="sm" placeholder="" type="date" :max="max_date"
                v-model="data[block.id][question.id]"></b-form-input>
</template>

<script>
import QuestionCommon from "./QuestionCommon.vue";
export default {
  data() {
    const now = new Date()
    const maxDate = now.toISOString().split('T')[0]
    return {
      max_date: maxDate,
    }
  },
  mixins: [QuestionCommon],
}
</script>