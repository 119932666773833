<template>
  <b-form-select :id="block.id+'_'+question.id" v-model="data[block.id][question.id]" size="sm"
                 class="mt-3" v-if="question.type=='selectyesno'">
    <b-form-select-option value="1">Yes</b-form-select-option>
    <b-form-select-option value="-1">No</b-form-select-option>
    <b-form-select-option value="0">N/A</b-form-select-option>
  </b-form-select>
</template>

<script>
import QuestionCommon from "./QuestionCommon.vue";
export default {
  mixins: [QuestionCommon]
}
</script>

<style scoped lang="scss">

</style>