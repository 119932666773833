<template>
  <b-row style="margin-bottom: 2em;">
    <b-col sm="6">
      <label :for="block.id+'_'+question.id" :class="{ alignText: block.subgroup_id !== 0 }"><span v-if="checklist">{{ question.id + 1 }}. </span>{{ question.label }}</label>
    </b-col>
    <b-col sm="6">

      <b-form-input :id="block.id+'_'+question.id" size="sm" placeholder=""
                    v-model="data[block.id][question.id]" v-if="question.type==='input'"></b-form-input>
      <b-form-input :id="block.id+'_'+question.id" size="sm" placeholder="" type="tel"
                    v-model="data[block.id][question.id]" v-if="question.type==='tel'"></b-form-input>
      <b-form-input :id="block.id+'_'+question.id" size="sm" placeholder=""
                    @input="thousandSeparator(block.id,question.id)" v-model="temp[block.id][question.id]"
                    v-if="question.type==='number' && forceUpdateInput"></b-form-input>

      <QuestionRange v-if="question.type==='range'" v-bind:block="block" v-bind:data="data" v-bind:question="question"></QuestionRange>
      <QuestionRadio v-if="question.type==='radio'" v-bind:block="block" v-bind:data="data" v-bind:question="question"></QuestionRadio>
      <QuestionSelectYesNo v-if="question.type==='selectyesno'" v-bind:block="block" v-bind:data="data" v-bind:question="question"></QuestionSelectYesNo>
      <QuestionTextarea v-if="question.type==='textarea'" v-bind:block="block" v-bind:data="data" v-bind:question="question"></QuestionTextarea>
      <QuestionSelect v-if="question.type==='select'" v-bind:block="block" v-bind:data="data" v-bind:question="question"></QuestionSelect>
      <QuestionDate  v-if="question.type==='date'" v-bind:block="block" v-bind:data="data" v-bind:question="question"></QuestionDate>

      <b-row v-if="tooltip(question)">
        <b-col style="text-align:left;font-style: italic;">
          {{ question.infos }}
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import QuestionSelectYesNo from "./QuestionSelectYesNo.vue";
import QuestionCommon from "./QuestionCommon.vue";
import QuestionRadio from "./QuestionRadio.vue";
import QuestionTextarea from "./QuestionTextarea.vue";
import QuestionSelect from "./QuestionSelect.vue";
import QuestionDate from "./QuestionDate.vue";
import QuestionRange from "./QuestionRange.vue";

export default {
  data() {
    return {
      forceUpdateInput: true,
    }
  },
  mixins: [QuestionCommon],
  components: {QuestionRange, QuestionDate, QuestionSelect, QuestionTextarea, QuestionRadio, QuestionSelectYesNo},
  props: {
    checklist: Boolean,
    temp: Object,
  },
  methods: {
    tooltip(question) {
      return Object.prototype.hasOwnProperty.call(question, 'infos') && question.infos != "";
    },
    thousandSeparator(b_id, q_id) {
      if (this.temp[b_id][q_id] !== '' || this.temp[b_id][q_id] !== undefined || this.temp[b_id][q_id] !== 0 || this.temp[b_id][q_id] !== '0' || this.temp[b_id][q_id] !== null) {
        this.temp[b_id][q_id] = this.temp[b_id][q_id].toString().replace(/\s/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        this.data[b_id][q_id] = parseInt(this.temp[b_id][q_id].toString().replace(/\s/g, ""));
        this.forceUpdateInput = false;
        this.forceUpdateInput = true;
      }
    }
  }
}
</script>

<style scoped>
  label {
    font-size: 1.2em;
  }

  .alignText {
    text-align: left;
    width: 100%;
  }
</style>