<template>
  <b-row>
    <b-form-group label="Individual radios">
      <b-form-radio v-model="data[block.id][question.id]" name="some-radios"
                    :value="question.content[indexContent].value"
                    v-for="(opt,indexContent) in question.content"
                    :key="indexContent">{{ question.content[indexContent].text }}
      </b-form-radio>
    </b-form-group>
  </b-row>
</template>

<script>
import QuestionCommon from "./QuestionCommon.vue";

export default {
  mixins: [QuestionCommon]
}
</script>

<style scoped lang="scss">

</style>