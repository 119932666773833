<template>
  <b-container class="login" fluid="xl">
    <b-row align-h="center" align-v="center">
      <b-col md="10" sm="12" style="position:relative;">
        <router-link to="/"><b-img src="../assets/logo.png" fluid alt="Logo Actoria" id="actoria-logo"></b-img></router-link>
      </b-col>
    </b-row>
    <b-row align-h="center" align-v="center" style="margin-top:2em;">
        <h5>{{$t("login.hello_world")}}</h5>
    </b-row>
    <b-row v-if="!isRegister && !isForget && !newPass">
      <b-col>
        <b-form @submit.prevent="onLogin" @reset="onReset">
          <b-row><b-col><h6>Login</h6></b-col></b-row>
          <b-row v-if="error">
            <b-col>{{msg}}</b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-row>
                  <b-col cols="2"><label id="login-label" for="login-input">{{$t('login.login_label')}}</label></b-col>
                <b-col><b-form-input
                  id="login-input"
                  v-model="login.email"
                  type="email"
                    :placeholder="$t('login.login_placeholder')"
                  required
                ></b-form-input></b-col>
              </b-row>
              <b-row>
                <b-col cols="2">
                    <label id="login-label" for="input-2">{{$t('login.password_label')}}</label>
                </b-col>
                <b-col>
                  <b-form-input
                    id="input-2"
                    type="password"
                    v-model="login.password"
                      :placeholder="$t('login.password_placeholder')"
                    required
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
            <b-row><b-col><b-button size="sm" target="_blank" type="submit" class="mr-1">{{$t('login.connection')}}</b-button></b-col></b-row>
            <b-row><b-col><b-button size="sm" target="_blank" @click="toForget()" class="mr-1">{{$t('login.password_forget')}}</b-button></b-col></b-row>
            <b-row><b-col><b-button size="sm" target="_blank" @click="toRegister()" class="mr-1">{{$t('login.register')}}</b-button></b-col></b-row>
        </b-form>
      </b-col>
    </b-row>
    <b-row v-if="isForget"><b-col>
      <b-form @submit.prevent="onForget" @reset="onReset">
          <b-row><b-col><h6>{{$t('login.password_forget')}}</h6></b-col></b-row>
        <b-row v-if="error">
          <b-col>{{msg}}</b-col>
        </b-row>
        <b-row v-if="mailForgetSended">
          <b-col><p>{{$t("login.change_pwd_sent")}}</p></b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-row>
                <b-col cols="2"><label id="forgot-label" for="forgot-email">{{$t('login.email_label')}}</label></b-col>
              <b-col><b-form-input
                id="forgot-email"
                v-model="forget.email"
                type="email"
                  :placeholder="$t('login.email_placeholder')"
                required
              ></b-form-input></b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
              <b-button size="sm" target="_blank" type="submit" class="mr-1">{{$t("login.submit_button")}}</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
              <b-button size="sm" target="_blank" @click="toForget()" class="mr-1">{{$t("login.back_button")}}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-col></b-row>
    <b-row v-if="newPass"><b-col>
      <b-form @submit.prevent="onNewPass" @reset="onReset">
          <b-row><b-col><h6>{{$t("login.new_password")}}</h6></b-col></b-row>
        <b-row v-if="error">
          <b-col>{{msg}}</b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-row>
                <b-col cols="2"><label id="newpass-label" for="newpass-pass">{{$t('login.new_password')}}</label></b-col>
              <b-col><b-form-input
                id="newpass-pass"
                v-model="new_pass.password"
                type="password"
                  :placeholder="$t('login.new_password_label')"
                required
              ></b-form-input></b-col>
            </b-row>
            <b-row>
                <b-col cols="2"><label id="newpass-confirm-label" for="newpass-pass-confirm">{{$t('login.new_password_verif')}}</label></b-col>
              <b-col><b-form-input
                id="newpass-pass-confirm"
                v-model="new_pass.verif_password"
                type="password"
                  :placeholder="$t('login.new_password_verif_label')"
                required
                @blur="checkpassword('new')"
              ></b-form-input></b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
              <b-button size="sm" target="_blank" type="submit" class="mr-1">{{$t("login.submit_button")}}</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
              <b-button size="sm" target="_blank" @click="toForget()" class="mr-1">{{$t("login.back_button")}}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-col></b-row>
    <b-row v-if="isRegister"><b-col>
      <b-form @submit.prevent="onRegister" @reset="onReset">
          <b-row><b-col><h4>{{$t('login.registration')}}</h4></b-col></b-row>
        <b-row v-if="error">
          <b-col>{{msg}}</b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-row>
                <b-col cols="2"><label id="register-label" for="register-email">{{$t('login.email_label')}}</label></b-col>
              <b-col><b-form-input
                id="register-email"
                v-model="register.email"
                type="email"
                  :placeholder="$t('login.email_placeholder')"
                required
              ></b-form-input></b-col>
            </b-row>
            <b-row>
                <b-col cols="2"><label id="register-pass-label" for="register-pass">{{$t('login.password_label')}}</label></b-col>
              <b-col><b-form-input
                id="register-pass"
                type="password"
                v-model="register.password"
                  :placeholder="$t('login.password_placeholder')"
                required
              ></b-form-input></b-col>
            </b-row>
            <b-row>
                <b-col cols="2"><label id="register-pass-label-verif" for="register-pass-conf">{{$t('login.password_label_verif')}}</label></b-col>
              <b-col><b-form-input
                  id="register-pass-conf"
                  type="password"
                  v-model="register.verif_password"
                    :placeholder="$t('login.password_placeholder_verif')"
                  @blur="checkpassword('register')"
                  required
                ></b-form-input></b-col>
            </b-row>
              <b-row v-if="password_check_error"><b-col>{{$t('login.password_not_matched')}}</b-col></b-row>
            <b-row>
                <b-col cols="2"><label id="register-label-nom" for="register-nom">{{$t('login.name_label')}}</label></b-col>
              <b-col><b-form-input
                id="register-nom"
                v-model="register.nom"
                type="text"
                  :placeholder="$t('login.name_placeholder')"
                required
              ></b-form-input></b-col>
            </b-row>
            <b-row>
                <b-col cols="2"><label id="register-label-prenom" for="register-prenom">{{$t('login.firstname_label')}}</label></b-col>
              <b-col><b-form-input
                id="register-prenom"
                v-model="register.prenom"
                type="text"
                  :placeholder="$t('login.firstname_placeholder')"
                required
              ></b-form-input></b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
              <b-button size="sm" target="_blank" type="submit" class="mr-1">{{$t('login.register')}}</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
              <b-button size="sm" target="_blank" @click="toRegister()" class="mr-1">{{$t("login.back_button")}}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
  </b-row>
  </b-container>
</template>

<script>

import {API_URL} from "../config";

export default {
name: 'Login',
data() {
  return {
    login: { password: '', email:''},
    register: { nom:'',prenom:'',email:'',password:'',verif_password:''},
    forget: {email:''},
    new_pass: {password:'',verif_password:''},
    isForget:false,
    isRegister: false,
    newPass: false,
    new_password_check_error:false,
    password_check_error: false,
    error: false,
    msg:'',
    server: API_URL,
  }
},
methods:{
  checkpassword(type){
    switch(type){
      case 'new':
        this.new_password_check_error = (this.new_pass.password!=this.new_pass.verif_password);
      break;
      case 'register':
        this.password_check_error = (this.register.password!=this.register.verif_password);
      break;
    }

  },
  toForget(){
    this.isForget = !this.isForget;
  },
  toRegister(){
    this.isRegister = !this.isRegister;
  },
  onReset(){
    this.login = { password: '', email:''};
    this.register = { nom:'',prenom:'',email:'',password:'',verif_password:''};
    this.forget = {email:''};
  },
  onLogin(){
    const baseURI = this.server+'/login';
    this.$http.post(baseURI,{identifiant:this.login.email,pass:this.login.password})
    .then((result) => {
      if(result.data.success){
          this.$store.dispatch('user/setUser',result.data.user);
          this.$store.dispatch('user/setRapport',result.data.rapports);
          this.$router.push('home');
      }else{
        this.error = true;
        this.msg = result.data.msg;
      }
    });
  },
  onNewPass(){
      if(!this.new_password_check_error){
        const baseURI = this.server+'/newPassword';
        this.$http.post(baseURI,{hash:decodeURIComponent(this.$route.params.hash),api:this.$route.params.api,pass:this.new_pass.password})
        .then((result) => {
          if(result.data.success){
            this.$store.dispatch('user/setUser',result.data.user);
            this.$store.dispatch('user/setRapport',result.data.rapports);

            this.$router.push('home');
          }else{
            this.error = true;
            this.msg = result.data.error;
          }
        });
      }
    },
  onRegister(){
    if(!this.password_check_error){
      const baseURI = this.server+'/register';
      this.$http.post(baseURI,{identifiant:this.register.email,pass:this.register.password,nom:this.register.nom,prenom:this.register.prenom})
      .then((result) => {
        if(result.data.success){
          this.$store.dispatch('user/setUser',result.data.user);
          this.$store.dispatch('user/setRapport',result.data.rapports);

          this.isRegister = false;
          this.error = true;
            this.msg = this.$t("login.register_success");
        }else{
          this.error = true;
          this.msg = result.data.error;
        }
      });
    }
  },
  onForget(){
    if(this.forget.email && this.forget.email!=""){
      const baseURI = this.server+'/forgetPassword';
      this.$http.post(baseURI,{email:this.forget.email})
      .then((result) => {
        if(result.data.success){
          this.mailForgetSended = true;
        }else{
          this.error = true;
          this.msg = result.data.error;
        }
      });
    }
  },
  },
  mounted(){
    localStorage.setItem("current-view", "Login");
    window.dispatchEvent(new CustomEvent('current-view-localstorage-changed', {
      detail: {
        storage: localStorage.getItem('current-view')
      }
    }));
    if(this.$store.state.user.user != undefined && Object.keys(this.$store.state.user.user).length !== 0){
      this.$router.push('home');
    }else if(this.$route.params.api && this.$route.params.hash){
      console.log(this.$route.params.hash);
      console.log(decodeURIComponent(this.$route.params.hash));
      this.newPass = true;
    }
  }
}
</script>

<style lang="scss">
.login{
  background: linear-gradient(122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);

  #actoria-logo{
    max-width: 50%;
    margin-bottom: 2em;
  }

  .btn {
    background: linear-gradient(122deg, rgba(0,134,203,1) 0%, rgba(29,27,89,1) 100%);
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
    color:white;
    padding:1em 2em;
    border-radius: 2em;
  }

  .shadow-box {
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
  }

  .inner-shadow-box{
    box-shadow: inset 5px 5px 5px 0px rgba(0,0,0,0.3), inset 4px 4px 15px 0px #fff;
    display: inline-block;
  }
  .home{
    .disabledTab{
      pointer-events: none;
    }
    .row{
      margin-bottom:1em;
    }
  }
}
</style>
