<template>
  <b-container class="report" fluid="xl" :style="cssProps">
    <b-row align-h="center" align-v="center">
      <b-col md="10" sm="12" style="position:relative;">
        <router-link to="/">
          <b-img src="../assets/logo.png" fluid alt="Logo Actoria" id="actoria-logo"></b-img>
        </router-link>
      </b-col>
    </b-row>
    <b-row align-h="center" align-v="center" style="margin-top:2em; margin-bottom:1.2em;">
      <h5>{{ $t(subtitle) }}</h5>
    </b-row>
    <b-nav pills>
      <template v-for="(block,index) in questions">
        <b-nav-item v-if="block.subgroup_id === 0" @click="changeStep(block.id - 1, 0)" :active="step === block.id - 1" :key="index">{{ block.libelle }}</b-nav-item>
      </template>
      <template v-for="(subgroup,subgroupIndex) in subgroups">
        <b-nav-item-dropdown :text="subgroup.libelle" :class="{ activeDropdown: subgroup_step === subgroup.id }" :active="subgroup_step === subgroup.id" :key="subgroupIndex + subgroup.id">
          <template v-for="(block,blockIndex) in questions">
            <b-dropdown-item v-if="subgroup.id === block.subgroup_id" @click="changeStep(block.id - 1, subgroup.id)" :active="step === block.id - 1"  :key="blockIndex">{{ block.libelle }}</b-dropdown-item>
          </template>
        </b-nav-item-dropdown>
      </template>
      <b-nav-item title-item-class="disabledTab" :disabled="last_tab_disabled" :active="step === questions.length" @click="changeStep(questions.length, 0)">{{ $t('report.report_title') }}</b-nav-item>
    </b-nav>
    <b-tabs pills v-model="step">
      <template v-for="(block,index) in questions">
        <b-tab @click="resetSubStep()" :key="index">
          <BlockQuestion v-bind:temp="temp" v-bind:sub_step="sub_step" v-bind:block="block" v-bind:data="data"
                         :go-home-parent="goHome" :next-parent="next" :prev-parent="prev"
                         :save-report-parent="saveReport"></BlockQuestion>
        </b-tab>
      </template>
      <b-tab title-item-class="disabledTab" :disabled="last_tab_disabled">
        <b-row>
          <b-col>
            <b-row v-if="!generating">
              <b-col>
                <h3>{{ $t("report.thanks_1") }}</h3>
                <p>{{ $t("report.generation_1") }}</p>
              </b-col>
            </b-row>
            <b-row v-if="generating">
              <b-col>
                <h3>{{ $t("report.thanks_2") }}</h3>
                <p>{{ $t("report.generation_2") }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button size="sm" target="_blank" @click="generate()" :href="fileurl" ref="fileURL" class="mr-1"
                          :disabled="generating">{{ $t("report.download_button") }}
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>

  </b-container>
</template>

<script>
import {API_URL, FILE_LABEL} from "../config";
import BlockQuestion from "../components/questions/BlockQuestion.vue";

export default {
  name: 'Report',
  components: {BlockQuestion},
  data() {
    return {
      generated: false,
      generating: false,
      fileurl: "", filename: "", fileurlpdf: "", filenamepdf: "",
      user: {},
      step: 0,
      subgroup_step: 0,
      sub_step: 0,
      questions: [],
      subgroups: [],
      data: {infos: {}},
      temp: {},
      last_tab_disabled: true,
      server: API_URL,
      type: '',
      subtitle: '',
      maincolorFrom: '',
      maincolorTo: '',
      file_label: FILE_LABEL,
    }
  },
  methods: {
    saveReport() {
      console.log("saveReport");
      const baseURI = this.server + '/' + this.type + '/generateRapport';
      this.data.infos['id_user'] = this.user.id
      if (this.currentRapport == -1) {
        console.log(this.rapports);
        this.$http.post(baseURI, {data: this.data, id: -1, lang: this.$i18n.locale})
            .then((result) => {
              if (result.data.success) {
                if (this.rapports.length > 0) {
                  this.rapports[this.type].unshift(result.data.rapport);
                } else {
                  this.rapports[this.type][0] = result.data.rapport;
                }
                this.$store.dispatch('user/setRapport', this.rapports);
                this.$router.push('/home');
              }
            });
      } else {
        console.log(this.rapports);
        let indexRapport = this.rapports[this.type].findIndex((item) => {
          return item.id == this.currentRapport.id
        });

        this.$http.post(baseURI, {
          data: this.data,
          id: this.currentRapport.id,
          created_at: this.rapports[this.type][indexRapport].created_at,
          lang: this.$i18n.locale
        })
            .then((result) => {
              if (result.data.success) {
                this.rapports[this.type][indexRapport].content = this.data;
                this.rapports[this.type][indexRapport].name = this.data["1"]["1"];
                this.rapports[this.type][indexRapport].generated = true;
                this.$store.dispatch('user/setRapport', this.rapports);
                this.$router.push('/home');
              }
            });
      }
    },
    generate() {
      if (!this.generated) {
        this.generating = true;
        console.log("generate");
        const baseURI = this.server + '/' + this.type + '/generateRapport';
        this.data.infos['id_user'] = this.user.id
        if (this.currentRapport == -1) {
          console.log(this.rapports);
          this.$http.post(baseURI, {data: this.data, id: -1, lang: this.$i18n.locale})
              .then((result) => {
                if (result.data.success) {
                  if (this.rapports[this.type].length > 0) {
                    console.log(this.rapports[this.type]);
                    console.log(this.type);
                    this.rapports[this.type].unshift(result.data.rapport);
                  } else {
                    this.rapports[this.type][0] = result.data.rapport;
                  }

                  this.generating = false;
                  this.fileurl = this.server + "/rapport_" + this.file_label[this.type] + "_" + this.user.id + "_" + result.data.rapport.id + ".docx";
                  this.filename = this.file_label[this.type] + "_" + this.user.id + "_" + this.currentRapport.id + ".docx";
                  this.downloadFile();
                  this.$store.dispatch('user/setRapport', this.rapports);
                  this.$router.push('/home');

                }
              });
        } else {
          console.log(this.rapports);
          let indexRapport = this.rapports[this.type].findIndex((item) => {
            return item.id == this.currentRapport.id
          });

          this.$http.post(baseURI, {
            data: this.data,
            id: this.currentRapport.id,
            created_at: this.rapports[this.type][indexRapport].created_at,
            lang: this.$i18n.locale
          })
              .then((result) => {
                if (result.data.success) {
                  this.rapports[this.type][indexRapport].content = this.data;
                  this.rapports[this.type][indexRapport].name = this.data["1"]["1"];
                  this.rapports[this.type][indexRapport].generated = true;

                  this.generating = false;
                  this.fileurl = this.server + "/rapport_" + this.file_label[this.type] + "_" + this.user.id + "_" + this.currentRapport.id + ".docx";
                  this.filename = this.file_label[this.type] + "_" + this.user.id + "_" + this.currentRapport.id + ".docx";
                  this.downloadFile();
                  this.$store.dispatch('user/setRapport', this.rapports);
                  this.$router.push('/home');
                }
              });
        }
      }
    },
    downloadFile() {
      // Create a new anchor element
      const a = document.createElement('a');

      // Set the href and download attributes for the anchor element
      // You can optionally set other attributes like `title`, etc
      // Especially, if the anchor element will be attached to the DOM
      a.href = this.fileurl;
      a.download = this.filename || 'download';

      // Programmatically trigger a click on the anchor element
      // Useful if you want the download to happen automatically
      // Without attaching the anchor element to the DOM
      // Comment out this line if you don't want an automatic download of the blob content
      a.click();
      console.log(this.fileurl);
    },
    resetSubStep() {
      this.sub_step = 0;
    },
    changeStep(index, subgroupIndex) {
      this.last_tab_disabled = false;
      this.resetSubStep();
      this.step = index;
      this.subgroup_step = subgroupIndex;
    },
    next() {
      this.last_tab_disabled = false;
      if (this.data[this.questions[this.step]['id']][this.questions[this.step]['question'][this.sub_step]['id']] == null) {
        this.data[this.questions[this.step]['id']][this.questions[this.step]['question'][this.sub_step]['id']] = "";
      }
      this.sub_step++;
      if (this.sub_step == this.questions[this.step]['question'].length || this.questions[this.step]['checklist'] == 1) {
        this.sub_step = 0;
        this.step++;
      }
      if(this.questions[this.step]) {
        this.subgroup_step = this.questions[this.step]['subgroup_id'];
      } else {
        this.subgroup_step = 0;
      }
    },
    prev() {
      if (this.sub_step == 0) {
        if (this.step > 0) {
          this.step--;
        }
      } else {
        this.sub_step--;
      }
    },
    goHome() {
      this.$router.push('home');
    },
    onLngLoad() {
      this.type = this.$store.state.user.current_app.type;
      this.maincolorFrom = this.$store.state.user.current_app.maincolorFrom;
      this.maincolorTo = this.$store.state.user.current_app.maincolorTo;
      this.subtitle = this.$store.state.user.current_app.subtitle;
      this.user = this.$store.state.user.user;
      this.rapports = this.$store.state.user.rapports;
      this.currentRapport = this.$store.state.user.current_rapport;
      console.log(this.user);
      console.log(this.rapports);
      console.log(this.type);
      const getQuestionsURL = this.server + '/' + this.type + '/questions?lang=' + this.$i18n.locale;
      this.$http.get(getQuestionsURL)
          .then((result) => {
            this.questions = result.data;
            if (result.data.length === 0) {
              this.$alert("No questions found for this application in this language.");
              this.$store.dispatch('user/setRapport', this.rapports);
              this.$router.push('/home');
            } else {
              this.questions.forEach((item) => {
                this.data[item.id] = {};
                item.question.forEach((item2) => {
                  if (this.currentRapport == -1) {
                    this.data[item.id][item2.id] = null;
                    if (item2.type == "range") {
                      this.data[item.id][item2.id] = (parseInt(item2.max) + parseInt(item2.min)) * 0.5;
                    } else if (item2.type == "number") {
                      if (this.temp[item.id] == undefined) this.temp[item.id] = {};
                      this.temp[item.id][item2.id] = null;
                    } else if (item2.type == "selectyesno") {
                      this.data[item.id][item2.id] = 0;
                    }
                  } else {
                    this.data[item.id][item2.id] = this.currentRapport.content[item.id][item2.id];
                    if (item2.type == "number") {
                      if (this.temp[item.id] == undefined) this.temp[item.id] = {};
                      this.temp[item.id][item2.id] = this.data[item.id][item2.id] != null ? this.data[item.id][item2.id].toString().replace(/\s/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ") : null;
                    }
                  }
                });
              });
            }
          });

      const getSubgroupURL = this.server + '/' + this.type + '/subgroup?lang=' + this.$i18n.locale;
      this.$http.get(getSubgroupURL)
          .then((result) => {
            this.subgroups = result.data;
          });
    }
  },
  computed: {
    cssProps() {
      return {
        '--maincolorGradientFrom': this.maincolorFrom,
        '--maincolorGradientTo': this.maincolorTo
      }
    },
  },
  mounted() {
    this.onLngLoad();
    localStorage.setItem("current-view", "Report");
    window.dispatchEvent(new CustomEvent('current-view-localstorage-changed', {
      detail: {
        storage: localStorage.getItem('current-view')
      }
    }));
  }
}
</script>

<style lang="scss">
#app .report {
  ul li a.active {
    background: linear-gradient(122deg, var(--maincolorGradientFrom) 0%, var(--maincolorGradientTo) 100%);
  }
  ul .activeDropdown .dropdown-toggle {
    background: linear-gradient(122deg, var(--maincolorGradientFrom) 0%, var(--maincolorGradientTo) 100%);
    color:white;
  }
}

.report {
  background: linear-gradient(122deg, rgba(248, 248, 252, 1) 0%, rgba(225, 226, 232, 1) 100%, rgba(0, 212, 255, 1) 100%);

  .nav-link {
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
    padding:1em 2em;
    border-radius: 2em;
    color: rgba(29,27,89,1);
    margin-left:1em;
    font-size: 15px;
    &.active{
      background: linear-gradient(122deg, rgba(0,134,203,1) 0%, rgba(29,27,89,1) 100%);
      color:white;
    }
  }

  ul[role="tablist"] {
    display: none;
  }

  .range_bubble {
    position: relative;
    margin: 0 auto 0;

    .bubble {
      background: linear-gradient(122deg, var(--maincolorGradientFrom) 0%, var(--maincolorGradientTo) 100%);
      color: white;
      padding: 4px 12px;
      position: absolute;
      border-radius: 4px;
      top: -2rem;
      left: 50%;
      transform: translateX(-50%);

      /*&::after {
        content: "";
        position: absolute;
        width: 2px;
        height: 2px;
        background: red;
        top: -1px;
        left: 50%;
      }*/
    }
  }

  .btn {
    background: linear-gradient(122deg, var(--maincolorGradientFrom) 0%, var(--maincolorGradientTo) 100%);
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3), 4px 4px 15px 0px #fff;
    color: white;
    padding: 1em 2em;
    border-radius: 2em;
  }


  .shadow-box {
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3), 4px 4px 15px 0px #fff;
  }

  .inner-shadow-box {
    box-shadow: inset 5px 5px 5px 0px rgba(0, 0, 0, 0.3), inset 4px 4px 15px 0px #fff;
    display: inline-block;
  }

  .report {
    .disabledTab {
      pointer-events: none;
    }

    .row {
      margin-bottom: 1em;
    }
  }

  #actoria-logo {
    max-width: 40%;
    margin-top: 2em;
  }

  .nav {
    padding-bottom: 1em;
  }
}
</style>
