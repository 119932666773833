<template>
  <b-container class="home" fluid="xl" :style="cssProps">
    <b-row align-h="center" align-v="center">
      <b-col md="10" sm="12" style="position:relative;">
        <router-link to="/"><b-img src="../assets/logo.png" fluid alt="Logo Actoria" id="actoria-logo"></b-img></router-link>
      </b-col>
    </b-row>
    <b-row v-if="user.is_admin==1">
      <b-col cols="2" offset="8">
        <b-button size="sm" target="_blank" @click="admin()" class="mr-1">{{$t("home.admin")}}</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2" offset="10">
        <b-button size="sm" target="_blank" @click="logout()" class="mr-1">{{$t("home.logout")}}</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button size="sm" target="_blank" @click="switchApp('valo')" class="mr-1 switchApp" :class="{ active : type == 'valo' }" v-if="access('valo')">{{$t("home.valorisation_360")}}</b-button>
        <b-button size="sm" target="_blank" @click="switchApp('audit')" class="mr-1 switchApp" :class="{ active : type == 'audit' }" v-if="access('audit')">{{$t("home.diagnosis_360")}}</b-button>
        <b-button size="sm" target="_blank" @click="switchApp('diag')" class="mr-1 switchApp" :class="{ active : type == 'diag' }" v-if="access('diag')">{{$t("home.strategic_diagnosis_360")}}</b-button>
        <b-button size="sm" target="_blank" @click="switchApp('esg')" class="mr-1 switchApp" :class="{ active : type == 'esg' }" v-if="access('esg')">{{$t("home.esg")}}</b-button>
      </b-col>
    </b-row>
    <b-row align-h="center" align-v="center" style="margin-top:2em;">
      <h5>{{this.$t(subtitle)}}</h5>
    </b-row>
    <b-row align-h="end">
      <b-col cols="5" md="2">
        <b-button size="sm" target="_blank" @click="editItem(-1)" class="mr-1">{{$t("home.new_report")}}</b-button>
      </b-col>
    </b-row>
    <b-row v-if="error">
      <b-col>
        {{msg}}
      </b-col>
    </b-row>
    <b-row v-if="rapports.length==0">
      <b-col>
          <p>{{$t("home.no_report")}}</p>
      </b-col>
    </b-row>
    <b-table responsive striped hover :items="rapports" :fields="fields" id="my-table" :per-page="perPage" :current-page="currentPage">
      <template #cell(actions)="row">
        <b-button size="sm" target="_blank" @click="editItem(row.item)" class="mr-1">{{$t("home.edit_button")}}</b-button>
        <b-button size="sm" target="_blank" :href="downloadDOC(row.item)" class="mr-1">
            {{$t("home.word_report_button")}}
        </b-button>
        <b-button size="sm" target="_blank" :href="downloadPDF(row.item)" class="mr-1">
            {{$t("home.pdf_report_button")}}
        </b-button>
        <b-button size="sm" target="_blank" @click="deleteItem(row.item)" class="mr-1">{{$t("home.delete_button")}}</b-button>
      </template>
    </b-table>

  </b-container>
</template>

<script>

import {API_URL, FILE_LABEL} from "../config";

export default {
name: 'Home',
data() {
  return {
    maincolorFrom: "#0086cb",
    maincolorTo: "#1d1b59",
    subtitle: "home.subtitle",
    error: false,
    perPage: 25,
    currentPage:1,
    msg:'',
    rapports : [],
    user: {},
    role:{},
    step: 0,
    questions: [],
    data: {
      infos:{}
    },
    file_label: FILE_LABEL,
    server: API_URL,
  }
},
methods:{
  access(type){
    var res = false;
    switch(type){
      case 'audit':
        res = (("audit" in this.role) && this.role.audit.includes('r'));
      break;
      case 'diag':
        res = (("diag" in this.role) && this.role.diag.includes('r'));
      break;
      case 'valo':
        res = (("valo" in this.role) && this.role.valo.includes('r'));
      break;
      case 'esg':
        res = (("esg" in this.role) && this.role.esg.includes('r'));
      break;
    }
    return res;
  },
  logout(){
    this.rapports = [];
    this.user = {};
    this.role = {};

    this.$store.dispatch('user/setUser',this.user);
    this.$store.dispatch('user/setRapport',this.rapports);
    this.$router.push('/');
  },
  downloadDOC(item){
    return this.server+"/rapport_"+this.file_label[this.type]+"_"+item.id_user+"_"+item.id+".docx";
  },
  downloadPDF(item){
    return this.server+"/rapport_"+this.file_label[this.type]+"_"+item.id_user+"_"+item.id+".pdf";
  },
  deleteItem(rapport){
    const baseURI = this.server+'/'+this.type+'/rapport/'+rapport.id+'/'+this.user.id;
    this.$http.delete(baseURI).then((result) => {
      if(result && result.data.success){
        let indexRapport = this.rapports.findIndex((item) => item.id==rapport.id);
        this.rapports.splice(indexRapport,1);
      }else{
        this.error = result.data.succes;
        this.msg = result.data.message;
      }
    });
  },
  admin(){
    this.$router.push('/admin');
  },
  editItem(rapport){
    console.log(rapport);
    console.log(this.$store.state.user.rapports);
      if(rapport !== -1 && rapport.lang !== this.$i18n.locale) {
        this.$alert("You cannot edit a report with another language.");
      } else {
    this.$store.dispatch('user/setCurrentRapport',rapport);
    this.$router.push('/report');
      }
  },
  switchApp(type){
    this.type = type;
    switch(type){
      case 'audit':
        this.maincolorFrom = "#cb000a";
        this.maincolorTo = "#591b1b";
          this.subtitle= "home.intangible_valuation_welcome";
      break;
      case 'diag':
        this.maincolorFrom = "#00cb43";
        this.maincolorTo = "#1b592f";
          this.subtitle= "home.strategic_diagnosis_360_welcome";
      break;
      case 'valo':
        this.maincolorFrom = "#0086cb";
        this.maincolorTo = "#1d1b59";
          this.subtitle= "home.valorisation_360_welcome";
      break;
      case 'esg':
        this.maincolorFrom = "#A21BFF";
        this.maincolorTo = "#3A0A5C"
        this.subtitle= "home.esg_welcome";
        break;
    }
    this.rapports = this.$store.state.user.rapports[this.type];
    this.$store.dispatch('user/setApp',{type:this.type,maincolorFrom:this.maincolorFrom,maincolorTo:this.maincolorTo,subtitle:this.subtitle});
  }
},
computed:{
    fields() {
          return [{
            key: 'name',
            label: this.$t("home.field_label_first"),
            sortable: true
          },{
            key: 'lang',
            label: "Language",
            sortable: true,
            formatter: value => {
              return this.$t(value)
            }
          },
          { key: 'actions', label: this.$t("home.field_label_second") }]
    },
  cssProps() {
    return {
      '--maincolorGradientFrom': this.maincolorFrom,
      '--maincolorGradientTo': this.maincolorTo
    }
  },
},
mounted(){
    localStorage.setItem("current-view", "Home");
    window.dispatchEvent(new CustomEvent('current-view-localstorage-changed', {
      detail: {
        storage: localStorage.getItem('current-view')
      }
    }));
  console.log("ok");
  console.log(this.$store.state.user.rapports);
  console.log(this.$store.state.user.current_app);
  if(typeof this.$store.state.user.current_app == "string"){
    this.type = this.$store.state.user.current_app;
    this.$store.state.user.current_app = { type: this.type };
  }
  this.type = this.$store.state.user.current_app.type;

  this.rapports = this.$store.state.user.rapports[this.type];
  this.user = this.$store.state.user.user;
  if(this.user.role==undefined){
    this.rapports = [];
    this.user = {};
    this.role = {};

    this.$store.dispatch('user/setUser',this.user);
    this.$store.dispatch('user/setRapport',this.rapports);
    this.$router.push('/');
  }else{
    this.role = JSON.parse(this.user.role);
    console.log(this.role);
    console.log(this.user);
    console.log(this.type);

    if(!this.access(this.type)){
      console.log(this.role);
      console.log("change type");
      this.type = "audit";
      if(!this.access(this.type)){
        this.type = "diag";
        if(!this.access(this.type)){
          this.type = "";
        }
      }
    }

    switch(this.type){
      case 'audit':
        this.maincolorFrom = "#cb000a";
        this.maincolorTo = "#591b1b";
        this.subtitle= "home.intangible_valuation_welcome";
      break;
      case 'diag':
        this.maincolorFrom = "#00cb43";
        this.maincolorTo = "#1b592f";
        this.subtitle= "home.strategic_diagnosis_360_welcome";
      break;
      case 'valo':
        this.maincolorFrom = "#0086cb";
        this.maincolorTo = "#1d1b59";
        this.subtitle= "home.valorisation_360_welcome";
      break;
      case 'esg':
        this.maincolorFrom = "#A21BFF";
        this.maincolorTo = "#3A0A5C"
        this.subtitle= "home.esg_welcome";
        break;
    }
    this.rapports = this.$store.state.user.rapports[this.type];
    this.user = this.$store.state.user.user;
  }
}
}
</script>

<style lang="scss">
body{
background: linear-gradient(122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
.btn {
  background: linear-gradient(122deg, var(--maincolorGradientFrom) 0%, var(--maincolorGradientTo) 100%);
  box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
  color:white;
  padding:1em 2em;
  border-radius: 2em;
  font-size: 0.8em;
  text-transform: uppercase;

  &.switchApp{
    box-shadow: 5px 5px 5px 0px rgb(0 0 0 / 30%), 4px 4px 15px 0px #fff;
    padding: 1em 2em;
    border-radius: 2em;
    color: #1d1b59;
    background: linear-gradient(122deg, #f8f8fc 0%, #e1e2e8 100%, #00d4ff 100%) !important;
    border: 0px;
    font-size:1.45em;

    &.active{
      background: linear-gradient(122deg, var(--maincolorGradientFrom) 0%, var(--maincolorGradientTo) 100%) !important;
      box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
      color:white;
    }
  }
}


.shadow-box {
  box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
}

.inner-shadow-box{
  box-shadow: inset 5px 5px 5px 0px rgba(0,0,0,0.3), inset 4px 4px 15px 0px #fff;
  display: inline-block;
}
.home{
  .disabledTab{
    pointer-events: none;
  }
  .row{
    margin-bottom:1em;
  }
  #actoria-logo{
    max-width: 40%;
    margin-top:2em;
    margin-bottom: 3em;
  }
}
}
</style>