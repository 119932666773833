<template>
  <b-form-select :id="block.id+'_'+question.id" v-model="data[block.id][question.id]"
                 :options="question.content" :multiple="question.multiple === 1" size="sm" class="mt-3"></b-form-select>
</template>

<script>
import QuestionCommon from "./QuestionCommon.vue";
export default {
  mixins: [QuestionCommon],
}
</script>