<template>
  <div>
    <b-row v-if="block.subgroup_id !== 0" align-h="center" align-v="center" style="margin-top:2em; margin-bottom:1.2em;">
      <h5>{{ block.libelle }}</h5>
    </b-row>
    <div v-if="block.checklist===1">
      <div v-for="(question,index2) in block.question" :key="index2">
        <Question v-bind:checklist="block.checklist===1" v-bind:block="block" v-bind:data="data"
                  v-bind:question="question" v-bind:temp="temp"></Question>
      </div>
    </div>
    <div v-if="block.checklist===0">
      <div v-for="(question,index2) in block.question" :key="index2">
        <Question v-if="sub_step===index2" v-bind:checklist="block.checklist===1" v-bind:block="block"
                  v-bind:data="data" v-bind:question="question" v-bind:temp="temp"></Question>
      </div>
    </div>
    <b-row>
      <b-col cols="2">
        <b-button size="sm" target="_blank" @click="prev()"
                  class="mr-1">{{ $t("report.before_button") }}
        </b-button>
      </b-col>
      <b-col cols="8">
      </b-col>
      <b-col cols="2">
        <b-button size="sm" target="_blank" @click="next()" class="mr-1">{{ $t("report.next_button") }}</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2">
        <b-button size="sm" target="_blank" class="mr-1"
                  @click="goHome()">{{ $t("report.home_button") }}
        </b-button>
      </b-col>
      <b-col cols="8">
      </b-col>
      <b-col cols="2">
        <b-button size="sm" target="_blank" class="mr-1"
                  @click="saveReport()">{{ $t("report.save_report_button") }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Question from "./Question.vue";
import QuestionCommon from "./QuestionCommon.vue";

export default {
  mixins: [QuestionCommon],
  components: {Question},
  props: {
    temp: Object,
    sub_step: Number,
    prevParent: Function,
    nextParent: Function,
    goHomeParent: Function,
    saveReportParent: Function,
  },
  methods: {
    prev() {
     this.prevParent();
    },
    next() {
      this.nextParent();
    },
    goHome() {
      this.goHomeParent();
    },
    saveReport() {
      this.saveReportParent();
    },
  }
}
</script>

<style scoped lang="scss">

</style>